<template>
  <v-card class="cardDialog" :height="height">
    <v-container style="background-color: #ffffff">
      <v-row>
        <v-col align="center" justify="center">
          <v-img
            class="imageYellowPetWarning"
            src="@/assets/img/yellow_pet_warning.svg"
            alt="Mascota saludando"
          />
        </v-col>
      </v-row>
      <v-row
        align="center"
        class="bodyAmarillo headerRoundedWhiteAmarilloWarning"
      >
        <v-col align="center" justify="center">
          <div style="margin: 25px" v-html="messageError" />
        </v-col>
      </v-row>
      <v-row align="center" class="bodyAmarillo">
        <v-col align="center" justify="center">
          <v-btn bottom depressed rounded class="btnTransparenteWarning" @click="close()" v-if="reintentar">
            Reintentar
          </v-btn>
        </v-col>
        <v-col align="center" justify="center">
          <v-btn bottom depressed rounded class="btnBlanco" @click="next()" v-if="reintentar">
            Continuar
          </v-btn>
        </v-col>
        <v-col align="center" justify="center">
          <v-btn bottom depressed rounded class="btnTransparenteWarning" @click="next()" v-if="salir">
            Salir
          </v-btn>
        </v-col>
        <v-col align="center" justify="center">
          <v-btn bottom depressed rounded class="btnBlanco" @click="close()" v-if="salir">
            Continuar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { homeRoute } from "@/helper/homeRoute";

export default {
  name: "WarningModal",
  data: () => ({
    messageError: null,
    btnHomeTxt: "",
    btnStay: "",
    height: "552px !important",
    reintentar: false,
    salir: false,
  }),
  mounted() {
    this.messageError =
      "Por favor volvé a intentar o contactate por WhatsApp\n" +
      "            con nuestro equipo de soporte.";
    let errorMessageState = this.$store.getters.getWarningMessage;
    if (errorMessageState !== "") {
      this.messageError = errorMessageState;
      if (this.messageError.includes("base")) {
        this.height = "564px !important";
        this.reintentar=true;
      }
      if (this.messageError.includes("sale")) {
        this.height = "504px !important";
        this.salir=true;
      }
      if (this.messageError.includes("ya sos cliente")) {
        this.height = "450px !important";
      }
    }
  },
  methods: {
    ...mapActions(["resetStore", "goWarningModal", "setWarningMessageModal"]),
    next() {
      this.goWarningModal(false);
      this.setWarningMessageModal("");
      this.resetStore();
      this.$router.replace({ name: homeRoute.HOME });
    },
    close() {
      this.goWarningModal(false);
      this.setWarningMessageModal("");
    },
  },
};
</script>

<style scoped>
</style>